<template>
  <div class="home">
    <a-alert banner closable type="warning" style="margin-bottom: 10px;">
      <div slot="message" class="alert-warning-msg">
        <span>操作提示：第一步</span>创建集群，添加机器； <span>第二步 </span>添加数据源。
      </div>
    </a-alert>
    <div v-if="userInfo.authArr.includes('SYSTEM_GET_RESOURCE_LIMIT')">
      <div class="title">系统资源状况</div>
      <div class="resource">
        <card :handleClickBtn="() => handleClickAddBtn('/system/machine' )" :num="summary.workerCounts"
              :show-btn="userInfo.menuItems.includes('/system/machine')"
              btn-text="添加"
              name="worker"
              quantifier="台" title="访问机器" router-name="/system/machine"/>
        <card :handleClickBtn="() => handleClickAddBtn('/system/datasource' )" :num="summary.dsCounts"
              :show-btn="userInfo.menuItems.includes('/system/datasource')"
              btn-text="添加"
              name="datasource"
              quantifier="个" title="数据源" router-name="/system/datasource"/>
        <card :handleClickBtn="() => handleClickAddBtn('/system/sub_account' )" :num="summary.subAccountCounts"
              :show-btn="userInfo.menuItems.includes('/system/sub_account')" btn-text="创建"
              name="subaccount" quantifier="个" title="子账号" router-name="/system/sub_account"/>
      </div>
    </div>
    <div v-if="userInfo.authArr.includes('SYSTEM_GET_DS_OP_AUDITS')">
      <div class="title">最近使用记录</div>
      <a-form layout="inline" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-form-item>
          <a-input style="width:200px" placeholder="请输入用户名" v-model="searchAuditKey.userName"/>
        </a-form-item>
        <a-form-item>
          <a-range-picker
            show-time
            format="YYYY-MM-DD HH:mm"
            @change="handleChangeTimeRanger"
          />
        </a-form-item>
        <a-form-item>
          <Button type="primary" @click="handleSearch">查询</Button>
        </a-form-item>
      </a-form>
      <a-table :columns="recordColumns" :data-source="recordList" :pagination="false"
               :row-key="record => record.executionId"
               size="small" :scroll="{ y: 500 }">
        <template slot="time" slot-scope="record">
          {{ record.executionDate | formatTime('YYYY-MM-DD hh:mm:ss') }}
        </template>
        <template slot="executionSql" slot-scope="record">
          <a-tooltip placement="right">
            <template slot="title">
              <span>{{record.executionSql}}</span>
            </template>
            <span class="content-need-omit">{{record.executionSql}}</span>
          </a-tooltip>
        </template>
        <template slot="success" slot-scope="record">
          <span :style="`color:${record.success?'#52C41A':'#FF1815'}`">{{ record.success?'成功':'失败' }}</span>
        </template>
        <template slot="dsAuthKindI8n" slot-scope="record">
          <span :style="`color:${record.dsAuthKindI8n!=='结构变更'?'#52C41A':'#FF1815'}`">{{ record.dsAuthKindI8n }}</span>
        </template>
        <template slot="execType" slot-scope="record">
          {{ record.execType === 'ONLINE_EXEC'?'在线执行': record.execType === 'DATA_EXPORT'? '异步执行':'' }}
        </template>
      </a-table>
      <cc-pagination @onChange="handlePageChange" style="float: right" :page="page" :hasNext="hasNext"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Card from './components/Card.vue';

export default {
  name: 'System_Home',
  data() {
    return {
      summary: {},
      recordColumns: [
        {
          title: 'SQL执行时间',
          scopedSlots: { customRender: 'time' },
          width: 180
        }, {
          title: '执行语句',
          scopedSlots: { customRender: 'executionSql' },
          minWidth: 300
        }, {
          title: '执行人',
          dataIndex: 'username',
          width: 100
        }, {
          title: '环境名称',
          dataIndex: 'envName',
          width: 100
        }, {
          title: '实例id',
          dataIndex: 'instanceId',
          width: 180
        }, {
          title: '涉及权限',
          dataIndex: 'authPath'
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'success' },
          width: 60
        },
        {
          title: '执行耗时(ms)',
          dataIndex: 'elapseMs',
          width: 120
        },
        {
          title: '影响行数',
          dataIndex: 'affectRows',
          width: 90
        },
        {
          title: 'SQL类型',
          scopedSlots: { customRender: 'dsAuthKindI8n' },
          width: 100
        },
        {
          title: '执行类型',
          scopedSlots: { customRender: 'execType' },
          width: 100
        }
      ],
      searchAuditKey: {
        userName: '',
        startExecDate: '',
        endExecDate: '',
        pageSize: 10,
        startId: 0
      },
      preStartIds: [],
      page: 1,
      hasNext: true,
      recordList: [],
      hasSummaryAuth: false,
      hasDsOpAuth: false
    };
  },
  components: {
    Card
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    })
  },
  watch: {
    'userInfo.authArr': {
      handler(newVal, oldVal) {
        if (newVal.length !== oldVal.length) {
          this.requestAuthApi(newVal);
        }
      },
      deep: true
    }
  },
  created() {
    this.requestAuthApi(this.userInfo.authArr);
  },
  methods: {
    requestAuthApi(authArr) {
      const hasSummaryAuth = authArr.includes('SYSTEM_GET_RESOURCE_LIMIT');
      const hasDsOpAuth = authArr.includes('SYSTEM_GET_DS_OP_AUDITS');

      if (hasSummaryAuth) {
        this.getSummary();
      }

      if (hasDsOpAuth) {
        this.getDbOpAudits();
      }

      this.hasSummaryAuth = hasSummaryAuth;
      this.hasDsOpAuth = hasDsOpAuth;
    },
    handleClickAddBtn(path) {
      // console.log(name);
      this.$router.push({ path });
      window.$bus.emit('changeSidebar', path);
    },
    async getSummary() {
      const res = await this.$services.getResourceSummary();
      if (res.success) {
        this.summary = res.data;
      }
    },
    async getDbOpAudits(type) {
      if (type) {
        if (type === 'left') {
          this.page--;
          this.searchAuditKey.startId = this.preStartIds[this.page - 1];
        }

        if (this.page < 0) {
          this.page = 1;
          this.searchAuditKey.startId = 0;
        }

        if (type === 'right') {
          this.searchAuditKey.startId = this.recordList[this.searchAuditKey.pageSize - 1].id;
          this.page++;
        }
      }
      const res = await this.$services.getDbOpAudits({
        data: {
          pageSize: this.searchAuditKey.pageSize,
          startId: this.searchAuditKey.startId,
          userName: this.searchAuditKey.userName || null,
          startExecDate: this.searchAuditKey.startExecDate || null,
          endExecDate: this.searchAuditKey.endExecDate || null
        }
      });
      if (res.success) {
        if (res.data.length) {
          if (type === 'right') {
            if (!this.preStartIds[this.page - 1]) {
              this.preStartIds.push(this.recordList[0].id);
            }
          }
          this.recordList = res.data;
          if (this.recordList.length >= this.searchAuditKey.pageSize) {
            this.hasNext = true;
          } else {
            this.hasNext = false;
          }
        } else {
          this.page--;
        }
      }
    },
    handleChangeTimeRanger(data) {
      this.searchAuditKey.startExecDate = data[0];
      this.searchAuditKey.endExecDate = data[1];
    },
    handlePageChange(type) {
      this.getDbOpAudits(type);
    },
    handleSearch() {
      this.page = 1;
      this.searchAuditKey.startId = 0;
      this.preStartIds = [];
      this.getDbOpAudits();
    }
  }
};
</script>

<style lang="less" scoped>
.home {
  width: 100%;

  .alert-warning-msg {
    span {
      font-weight: bold;
    }
  }

  .title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .resource {
    margin-bottom: 20px;
    padding-left: 40px;
    height: 136px;
    width: 100%;
    background: @background-grey;
    display: flex;
    align-items: center;

    & > div {
      margin-right: 160px;
    }
  }
}
.content-need-omit {
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
  display: block;
}

</style>
