<template>
  <Params />
</template>
<script>
import Params from '../datasource/Params.vue';

export default {
  components: { Params }
};
</script>
